
import 'vue-class-component'
import {Component, Prop, Model, Emit, Vue, Watch} from 'vue-property-decorator';
import {FenceItemModel, FenceItemPosition, FenceItemType} from "../../../types/editor";
import {ViewPort,PriceModel} from "@/util/configurator";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import {EventBus} from "@/plugins/event-bus";
import { ref } from 'vue';

@Component
export default class PdfMapPrinter extends Vue {

  activated = false;

  @Prop({
    required: true
  })
  public fenceItems: Array<FenceItemModel>;

  @Prop({
    required: true
  })
  public prices: PriceModel;

  running = true;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  viewPort: ViewPort = {
    x: 0,
    y: 0,
    renderedX: 0,
    renderedY: 0,
    targetRotate: 0,
    rotate: 0,
    size: {
      width: 4500 *2,
      height: 3600 * 2
    }
  }

  context: CanvasRenderingContext2D = null;

  getMinimumYItem(): FenceItemModel {
    let minimumStartY = this._.minBy(this.fenceItems, function (o) {
      return o.position.start.y
    });
    let minimumEndY = this._.minBy(this.fenceItems, function (o) {
      return o.position.end.y
    });
    if (minimumStartY != null) {
      if (minimumEndY != null) {
        if (Math.min(minimumEndY.position.start.y, minimumEndY.position.end.y) < Math.min(minimumStartY.position.start.y, minimumStartY.position.end.y)) {
          return minimumEndY;
        } else {
          return minimumStartY;
        }
      } else {
        return minimumStartY;
      }
    } else if (minimumEndY != null) {
      return minimumEndY;
    }
    return null;
  }

  getMinimumXItem(): FenceItemModel {
    let minimumStartX = this._.minBy(this.fenceItems, function (o) {
      return o.position.start.x
    });
    let minimumEndX = this._.minBy(this.fenceItems, function (o) {
      return o.position.end.x
    });
    if (minimumStartX != null) {
      if (minimumEndX != null) {
        if (Math.min(minimumEndX.position.start.x, minimumEndX.position.end.x) < Math.min(minimumStartX.position.start.x, minimumStartX.position.end.x)) {
          return minimumEndX;
        } else {
          return minimumStartX;
        }
      } else {
        return minimumStartX;
      }
    } else if (minimumEndX != null) {
      return minimumEndX;
    }
    return null;
  }

  getMaximumYItem(): FenceItemModel {
    let maximumStartY = this._.maxBy(this.fenceItems, function (o) {
      return o.position.start.y
    });
    let maximumEndY = this._.maxBy(this.fenceItems, function (o) {
      return o.position.end.y
    });
    if (maximumStartY != null) {
      if (maximumEndY != null) {
        if (Math.max(maximumEndY.position.end.y, maximumEndY.position.start.y) > Math.max(maximumStartY.position.end.y, maximumStartY.position.start.y)) {
          return maximumEndY;
        } else {
          return maximumStartY;
        }
      } else {
        return maximumStartY;
      }
    } else if (maximumEndY != null) {
      return maximumEndY;
    }
    return null;
  }

  getMaximumXItem(): FenceItemModel {
    let maximumStartX = this._.maxBy(this.fenceItems, function (o) {
      return o.position.start.x
    });
    let maximumEndX = this._.maxBy(this.fenceItems, function (o) {
      return o.position.end.x
    });
    if (maximumStartX != null) {
      if (maximumEndX != null) {
        if (Math.max(maximumEndX.position.end.x, maximumEndX.position.start.x) > Math.max(maximumStartX.position.end.x, maximumStartX.position.start.x)) {
          return maximumEndX;
        } else {
          return maximumStartX;
        }
      } else {
        return maximumStartX;
      }
    } else if (maximumEndX != null) {
      return maximumEndX;
    }
    return null;
  }

  getMinimumX(): number {
    let minimumXItem = this.getMinimumXItem();
    return (minimumXItem != null && minimumXItem.position != null) ? Math.min(minimumXItem.position.start.x, minimumXItem.position.end.x) : 0;
  }

  getMinimumY(): number {
    let minimumXItem = this.getMinimumYItem();
    return (minimumXItem != null && minimumXItem.position != null) ? Math.min(minimumXItem.position.start.y, minimumXItem.position.end.y) : 0;
  }

  get cViewPortBumpY(): number {
    return this.getMinimumY() < 0 ? Math.abs(this.getMinimumY()) : 0;
  }

  get cViewPortBumpX(): number {
    return this.getMinimumX() < 0 ? Math.abs(this.getMinimumX()) : 0;
  }

  getMaximumX(): number {
    let maximumXItem = this.getMaximumXItem();
    return (maximumXItem != null && maximumXItem.position != null) ? Math.max(maximumXItem.position.start.x, maximumXItem.position.end.x) + this.cViewPortBumpX : 0;
  }

  getMaximumY(): number {
    let maximumYItem = this.getMaximumYItem();
    return (maximumYItem != null && maximumYItem.position != null) ? Math.max(Math.max(maximumYItem.position.start.y, maximumYItem.position.end.y), Math.max(maximumYItem.position.start.y, maximumYItem.position.end.y)) + this.cViewPortBumpY : 0;
  }

  get cHeightHalf(): number {
    return this.context.canvas.height / 2;
  }

  get cWidthHalf(): number {
    return this.context.canvas.width / 2;
  }

  get cHeight(): number {
    return this.context.canvas.height;
  }

  get cWidth(): number {
    return this.context.canvas.width;
  }

  get cPadding(): number {
    return 80;
  }

  get cHeightWithPadding(): number {
    return this.cHeight - this.cPadding;
  }

  get cWidthWithPadding(): number {
    return this.cWidth - this.cPadding;
  }

  get cFraction(): number {
    return Math.min((this.cHeightWithPadding / this.getMaximumY()), (this.cWidthWithPadding / this.getMaximumX()));
  }

  relateItemPositionToPDFViewPort(position: FenceItemPosition): FenceItemPosition {

    return {
      start: {
        x: (this.cPadding / 2) + (this.cFraction * (position?.start?.x + this.cViewPortBumpX)),
        y: (this.cPadding / 2) + (this.cFraction * (position?.start?.y + this.cViewPortBumpY))
      }, end: {
        x: (this.cPadding / 2) + (this.cFraction * (position?.end?.x + this.cViewPortBumpX)),
        y: (this.cPadding / 2) + (this.cFraction * (position?.end?.y + this.cViewPortBumpY))
      }
    }
  }

  draw(time: number): void {
    if(this.context == null && this.$refs.pdfcanvas !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.context = this.$refs.pdfcanvas.getContext("2d");
    }

    // const rec = new Path2D();
    // rec.rect(20, 50,100,120);
    //
    // this.context.fillStyle = "white";
    // this.drawRect(0,0,this.context.canvas.width, this.context.canvas.height);
    //
    // this.context.fillStyle = "blue"
    // this.context.fill(rec);

    this.drawFenceItems();
    if(this.running) {
  //    requestAnimationFrame(this.draw);
    }
  //  this.renderPDF();
  }

  drawRect(x: number, y: number, width: number, height: number): void {
    const rect = new Path2D();
    rect.rect(x, y,width,height);
    this.context.fill(rect);
  }

  drawFenceItems(): void {
    if(this.context != null) {
   //   this.context.save();

   //   this.context.setTransform(1, 0, 0, 1, 0, 0);

     // this.context.restore();


      this.context.clearRect(0,0,this.context.canvas.width, this.context.canvas.height);
      //draw background
      this.context.fillStyle = "white";
      this.drawRect(0,0,this.context.canvas.width, this.context.canvas.height);

      //draw border
      // this.context.fillStyle = "black";
      // this.drawRect(0,0,2, this.context.canvas.height);
      // this.drawRect(0,0,this.context.canvas.width, 2);
      // this.drawRect(this.context.canvas.width-2, 0, 2, this.context.canvas.height);
      // this.drawRect(0, this.context.canvas.height-2, this.context.canvas.width, 2);
     // this.drawRect(0,0,1, 1);
    }

    let fenceItemToRender = this.fenceItems;

    for(let i = 0; i < fenceItemToRender.length; i++) {
      let position = this.relateItemPositionToPDFViewPort(fenceItemToRender[i].position);
      this.context.fillStyle = 'grey';

      let minimumX: number;
      let maximumX: number;
      let minimumY: number;
      let maximumY: number;
      if(position.start.x <= position.end.x) {
        minimumX = position.start.x;
        maximumX = position.end.x;
      }else {
        minimumX = position.end.x;
        maximumX = position.start.x;
      }
      if(position.start.y <= position.end.y) {
        minimumY = position.start.y;
        maximumY = position.end.y;
      }else {
        minimumY = position.end.y;
        maximumY = position.start.y;
      }

      if(minimumX < 0 || minimumY < 0) {
      //  this.context.fillStyle = 'red';
      }
      // TODO: Wir befinden uns im linken oberen oder unteren Quadranten -> setze den Nullpunkt in die Mitte des canvas
      const fence = new Path2D();
      fence.rect(minimumX-3.5, minimumY-3.5, Math.max( Math.abs(minimumX - maximumX), 4), Math.max(Math.abs(minimumY - maximumY), 4));
      this.context.fill(fence);
      if(fenceItemToRender[i].type === FenceItemType.FenceField) {
        this.context.fillRect(minimumX-5.5, minimumY-5.5, 7.5, 7.5);
        this.context.fillRect(maximumX-5.5, maximumY-5.5, 7.5, 7.5);
      }

      //TODO: Implement the Rotation
      if (fenceItemToRender[i].type === FenceItemType.FenceField) {
        // width > height
        if(Math.max( Math.abs(minimumX - maximumX), 4)>Math.max(Math.abs(minimumY - maximumY), 4)) { // minimumX-maximumX -> wtf?
          // 20 -> ungefähre lenge des Textes
          this.context.strokeText('H: ' + fenceItemToRender[i].field.totalFenceHeight.toFixed() + ' cm', Math.abs((minimumX - maximumX) / 2 + maximumX - 20), Math.abs((minimumY - maximumY) / 2 + maximumY -7));
        }else{
          //rotate label on y-axis
          // 60 -> den Text auf die andere Seite bringen
          this.context.strokeText('H: ' + fenceItemToRender[i].field.totalFenceHeight.toFixed() + ' cm', Math.abs((minimumX - maximumX) / 2 + maximumX - 60 ), Math.abs((minimumY - maximumY) / 2 + maximumY));
        }
      } else if(fenceItemToRender[i].type === FenceItemType.FenceDoor) {
        this.context.strokeText('Tor', minimumX-3.5, minimumY-3.5);
      }
    }
  }

  closePDFMap(): void {
    this.activated = false;
    EventBus.$emit('pdf-map-close');
  }

  formatCurrency(value) : string{
    if (isNaN(parseFloat(value))) {
      return value;
    }
    var formatter = new Intl.NumberFormat('de', {
      style: 'currency',
      currency: 'EUR'
    });
    return formatter.format(value);
  }


  renderPDF(): void {
    if(this.context != null &&  this.$refs.pdfcanvas !== undefined){ //&& this.$refs.priceTable) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let canvas = this.$refs.pdfcanvas.toDataURL("image/jpeg", 1.0);
      //let priceTable = this.$refs.priceTable.toString();
      let priceTable = [
        {x:this.prices.plateCount + 'x Mauerplatten',y:this.formatCurrency(this.prices.pricePlates.toFixed(2))},
        {x:this.prices.postCount + 'x Pfosten',y:this.formatCurrency(this.prices.pricePosts.toFixed(2))},
        {x:'Sonstiges',y:this.formatCurrency(this.prices.priceCoverAndOther.toFixed(2))},
        {x:'Gesamtsumme',y:this.formatCurrency((this.prices.pricePlates+this.prices.pricePosts+this.prices.priceConcreteAndColor+this.prices.priceCoverAndOther).toFixed(2))},
      ]

      let doc = new jsPDF();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      doc.text("Mein Betonzaun",13,10)
      doc.lines([[0,184],[184,0],[0,-184]],13,70,[1,1],'S',true);
      doc.table(13,15,priceTable,['x','y'], {printHeaders : false});
      doc.addImage(canvas, 'JPEG', 15, 72, 180, 180);
      doc.output('save','betonzaun.pdf');

    }
  }

  onOpenPDFMap(): void {
    this.activated = true;
    this.draw(new Date().getTime());
  }

  created(): void {
    EventBus.$on('pdf-map', this.onOpenPDFMap);
  }

  mounted(): void {
    this.running = true;
    //this.draw(new Date().getTime());
  }

  unmounted(): void {
    this.running = false;
  }
}
