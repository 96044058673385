
import {Component, Prop, Model, Emit, Vue, Watch} from "vue-property-decorator";
import InputSwitch from "@/components/inputs/InputSwitch.vue";
import InputDropdown, {InputDropdownOption} from "@/components/inputs/InputDropdown.vue";

export interface DropdownActionItemOption {
  name: string;
  value: any;
}

export enum ActionItemType {
  switch,
  variant,
  dropdown
}

export interface SwitchActionItem extends ActionItem {
  type: ActionItemType.switch;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function;
  enabled: boolean;
}

export interface DropdownActionItem extends ActionItem {
  type: ActionItemType.dropdown;
  options: Array<InputDropdownOption>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function;
}

export interface VariantSelectActionItem extends ActionItem {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSelect: Function;
  type: ActionItemType.variant;
  thumbnailUrl: string;
  price: string;
  productNumber: string;
}

export interface ActionItem {
  name: string;
  type: ActionItemType;
}

export interface Action {
  label: string;
  items: Array<ActionItem>;
}

@Component({
  components: {InputDropdown, InputSwitch}
})
export default class PopoverSettings extends Vue{

  dropdownKey = 0;

  @Model('change', {
    default: false,
    type: Boolean
  })
  public active: boolean;

  @Prop({
    required: true
  })
  readonly actions: Array<Action>;

  @Prop({
    default: ''
  })
  readonly tag: string;

  $refs!: {
    popover: HTMLDivElement
  }

  v = 0;

  defaultHeight = 600;
  defaultWidth = 400;

  activated = false;

  currentMousePosition = {
    x: 0,
    y: 0
  }

  currentScrollPosition = 0;
  targetScrollPosition = 0;
  initialScrollPosition = 0;

  position = {
    x: 0,
    y: 0
  }

  get getY(): number {
    return this.wrapY(this.position.y - (this.currentScrollPosition - this.initialScrollPosition));
  }

  get getYNoWrap(): number {
    return  this.positive(this.currentScrollPosition - this.initialScrollPosition);
  }

  get getX(): number {
    return this.wrapX(this.position.x);
  }

  get height(): number {
    if(this.$refs.popover !== undefined) {
      // eslint-disable-next-line
      return this.$refs.popover.scrollHeight;
    }else {
      return this.defaultHeight;
    }
  }

  get width(): number {
    if(this.$refs.popover !== undefined) {
      // eslint-disable-next-line
      return this.$refs.popover.scrollWidth;
    }else {
      return this.defaultWidth;
    }
  }

  wrapX(n: number): number {
    if(n < 0) {
      n = 0;
    }
    if(n > window.innerWidth-this.width) {
      n = window.innerWidth-this.width;
    }
    return n;
  }

  wrapY(n: number): number {
    if(n < 0) {
      n = 0;
    }
    if(n > window.innerHeight-this.height) {
      n = window.innerHeight-this.height;
    }
    return n;
  }

  onEnter(): void {
    this.defaultWidth--;
    this.defaultHeight--;
    if(this.v > 5) {
      this.v = 0;
    }
    this.v++;
    this.$forceUpdate();
  }

  positive(n: number): number {
    if(n < 0) {
      n = 0;
    }
    return n;
  }

  onDropdownChange(selected: {label: string; value: string;}, item: DropdownActionItem): void {
    item.onChange(selected);
    this.dropdownKey++;
  }

  onMouseMove(event): void {
    this.currentMousePosition.x = event.clientX;
    this.currentMousePosition.y = event.clientY;
  }

  onScroll(event): void {
    this.currentScrollPosition = window.scrollY;
  }

  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    document.addEventListener('mousemove', this.onMouseMove);
    this.currentScrollPosition = window.scrollY;
    this.initialScrollPosition = this.currentScrollPosition;
    document.addEventListener('scroll', this.onScroll);
  }
  destroyed(): void {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('scroll', this.onScroll);
    this.disable();
  }

  onSelectAction(action: VariantSelectActionItem): void {
    if(action.onSelect !== undefined) {
      action.onSelect(action, this.tag);
    }
    this.disable();
  }

  updatePosition(): void {
    this.position.x = this.currentMousePosition.x-(this.width/2);
    this.position.y = this.currentMousePosition.y-(this.height/4);
    this.targetScrollPosition = this.currentScrollPosition;
    this.initialScrollPosition = this.targetScrollPosition;
  }
  lastActive = false;
  updated(): void {
    if(this.lastActive === false && this.activated === true) {
      this.$nextTick(function () {
        this.updatePosition();
      });
      this.lastActive = this.activated;
    }else if(this.lastActive === true && this.activated === false) {
      this.lastActive = this.activated;
    }
  }

  @Emit('change')
  toggle(): boolean {
    this.activated = !this.activated;
    return this.activated;
  }

  @Emit('change')
  enable(): boolean {
    this.activated = true;
    this.updatePosition();
    return true;
  }

  @Emit('change')
  disable(): boolean {
    this.activated = false;
    return false;
  }

  getSwitchActionItems(action: Action): Array<SwitchActionItem> {
    if(action != null && action.items != null) {
      let filteredItems = action.items.filter(value => value.type === ActionItemType.switch);
      return filteredItems != null ? filteredItems as Array<SwitchActionItem> : [] as Array<SwitchActionItem>;
    }
    return  [] as Array<SwitchActionItem>;
  }

  getDropdownActionItems(action: Action): Array<DropdownActionItem> {
    if(action != null && action.items != null) {
      let filteredItems = action.items.filter(value => value.type === ActionItemType.dropdown);
      return filteredItems != null ? filteredItems as Array<DropdownActionItem> : [] as Array<DropdownActionItem>;
    }
    return [] as Array<DropdownActionItem>;
  }

  getDropdownOptions(dropdownOptions: Array<DropdownActionItemOption>, selectedIndex: number): Array<InputDropdownOption> {
    return  dropdownOptions.map((value, index) => {
      return {
        label: value.name,
        value: value.value,
        selected: index === selectedIndex
      };
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  variantSelectItemsCache: Map<Action, Array<any>> = {};

  getVariantSelectItems(action: Action): Array<VariantSelectActionItem> {
    if(action != null && action.items != null) {
      let filteredItems = action.items.filter(value => value.type === ActionItemType.variant) as Array<VariantSelectActionItem>;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if(this.variantSelectItemsCache[action] !== undefined) {
        let toDelete: Array<VariantSelectActionItem> = [];
        let toAdd: Array<VariantSelectActionItem> = [];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let cachedItems: Array<VariantSelectActionItem> = this.variantSelectItemsCache[action];
        for(let i = 0; i < cachedItems.length; i++) {
          if(!filteredItems.some(value => value.productNumber === cachedItems[i].productNumber)) {
            toDelete.push(cachedItems[i]);
          }
        }
        for(let i = 0; i < filteredItems.length; i++) {
          if(!cachedItems.some(value => value.productNumber === filteredItems[i].productNumber)) {
            toAdd.push(filteredItems[i]);
          }
        }
        for(let i = 0; i < toDelete.length; i++) {
          let indexToRemove = cachedItems.findIndex(value => value.productNumber === toDelete[i].productNumber);
          if(indexToRemove !== -1) {
            cachedItems.splice(indexToRemove, 1);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.variantSelectItemsCache[action] = cachedItems;
          }
        }
        for(let i = 0; i < toAdd.length; i++) {
          cachedItems.push(toAdd[i]);
        }
      }else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.variantSelectItemsCache[action] = filteredItems;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.variantSelectItemsCache[action] != null ? this.variantSelectItemsCache[action] as Array<VariantSelectActionItem> : [] as Array<VariantSelectActionItem>;
    }
    return [] as Array<VariantSelectActionItem>;
  }

  @Watch('active')
  activeStateChanged(newVal: boolean): void {
    this.activated = newVal;
    this.updatePosition();
  }

  @Watch('activated')
  activatedStateChanged(newVal: boolean): void {
    this.active = newVal;
    this.updatePosition();
  }
}
