
import 'vue-class-component'
import {Component, Model, Prop, Vue} from 'vue-property-decorator';
import {FenceItemModel, FenceItemPosition, FenceItemType} from "../../../types/editor";
import {ViewPort} from "@/util/configurator";
import {EventBus} from '@/plugins/event-bus'

@Component
export default class TopViewPort extends Vue {

  @Model('change', {
    default: []
  })
  public fenceItems: Array<FenceItemModel>;

  @Prop({
    default: false
  })
  readonly debugging: boolean;

  @Prop({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    type: ViewPort
  })
  readonly actualViewPort: ViewPort;

  @Prop({
    type: Number,
    default: 0
  })
  readonly selectedFenceItemIndex: number;

  @Prop({
    type: Number,
    default: 0
  })
  readonly cachedFenceItems: number;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  viewPort: ViewPort = {
    x: 0,
    y: 0,
    renderedX: 0,
    renderedY: 0,
    targetRotate: 0,
    rotate: 0,
    size: {
      width: 4500 * 0.65,
      height: 3600 * 0.65
    }
  }

  cursor = 'default';

  get vX(): number {
    return this.viewPort.x + this.vXRotationAdjustment
  }

  get vY(): number {
    return this.viewPort.y + this.vYRotationAdjustment
  }

  get vXInterpolated(): number {
    return this.viewPort.renderedX + this.vXRotationAdjustment
  }

  get vYInterpolated(): number {
    return this.viewPort.renderedY + this.vYRotationAdjustment
  }

  get vYTop(): number {
    return this.vY - (this.viewPort.size.height / 2);
  }
  get vXLeft(): number {
    return this.vX - (this.viewPort.size.width / 2);
  }

  get vXRotationAdjustment(): number {
    let amt = 450;
    let currentFenceItem = this.fenceItems.find(value => value.id === this.selectedFenceItemIndex);
    if(currentFenceItem != null && currentFenceItem.type === FenceItemType.FenceDoor) {
      amt = 323/2;
    }
    if(this.viewPort.targetRotate === 0 || this.viewPort.targetRotate === 360) {
      return -amt;
    }else if(this.viewPort.targetRotate === 180) {
      return amt;
    }
    return 0;
  }

  get vYRotationAdjustment(): number {
    let amt = 450;
    let currentFenceItem = this.fenceItems.find(value => value.id === this.selectedFenceItemIndex);
    if(currentFenceItem != null && currentFenceItem.type === FenceItemType.FenceDoor) {
      amt = 323/2;
    }
    if(this.viewPort.targetRotate === 90) {
      return -amt;
    }else if(this.viewPort.targetRotate === 270) {
      return amt;
    }
    return 0;
  }

  FRONT = 0;

  running = true;

  context: CanvasRenderingContext2D = null;
  ovContext: CanvasRenderingContext2D = null;

  lerp(start: number, end: number, amt: number): number {
    return end;
  }
  lerpDegrees(start: number, end: number, amt: number): number {
    return end;
  }

  getAngle(item: FenceItemModel): number {
    return Math.atan2(item.position.start.y - item.position.end.y, item.position.start.x - item.position.end.x) * 180 / Math.PI + 180;
  }

  rotateCanvas(deg: number): void {
    let w = this.context.canvas.width;
    let h = this.context.canvas.height;
    this.context.translate(w * 0.5, h * 0.5);
    this.context.rotate(deg * (Math.PI / 180));
    this.context.translate(-(w * 0.5), -(h * 0.5));
  }

  get vHeightFraction(): number {
    return 1.5/this.viewPort.size.height;
  }

  get vWidthFraction(): number {
    return 1.5/this.viewPort.size.width;
  }

  get cHeightHalf(): number {
    return this.context.canvas.height / 2;
  }

  get cWidthHalf(): number {
    return this.context.canvas.width / 2;
  }

  get cHeight(): number {
    return this.context?.canvas?.height;
  }

  get cWidth(): number {
    return this.context?.canvas?.width;
  }

  relateItemPositionToViewPort(position: FenceItemPosition): FenceItemPosition {
    return {
      start: {
        x: this.cWidth * (this.vWidthFraction * (position?.start?.x + this.vXInterpolated)) + this.cWidthHalf,
        y: this.cHeight * (this.vHeightFraction * (position?.start?.y + this.vYInterpolated)) + this.cHeightHalf
      }, end: {
        x: this.cWidth * (this.vWidthFraction * (position?.end?.x + this.vXInterpolated)) + this.cWidthHalf,
        y: this.cHeight * (this.vHeightFraction * (position?.end?.y + this.vYInterpolated)) + this.cHeightHalf
      }
    }
  }

  drawDottedLine(x: number, y: number, width: number, height: number): void {
    this.ovContext.beginPath();
    this.ovContext.setLineDash([10, 8]);
    this.ovContext.moveTo(x, y);
    this.ovContext.lineTo(width, height);
    this.ovContext.stroke();
  }

  showItem(item: FenceItemModel): boolean {
    if (item != null && item.position != null && item.position.end != null && item.position.start != null) {
      let position = item.position;
      let width = this.viewPort.size.width * 5;
      let height = this.viewPort.size.height * 5;
      let minimumX: number;
      let maximumX: number;
      let minimumY: number;
      let maximumY: number;
      if(position.start.x <= position.end.x) {
        minimumX = position.start.x;
        maximumX = position.end.x;
      }else {
        minimumX = position.end.x;
        maximumX = position.start.x;
      }
      if(position.start.y <= position.end.y) {
        minimumY = position.start.y;
        maximumY = position.end.y;
      }else {
        minimumY = position.end.y;
        maximumY = position.start.y;
      }
      let vXMin = this.vXInterpolated - (width / 2);
      let vXMax = this.vXInterpolated + (width / 2);
      let vYMin = this.vYInterpolated - (height / 2);
      let vYMax = this.vYInterpolated + (height / 2);
      return minimumX >= vXMin && maximumX <= vXMax && minimumY >= vYMin && maximumY <= vYMax;
    }
    return false;
  }

  lastRotate = 0;
  drawFenceItems(): void {
    if(this.lastRotate !== this.viewPort.rotate) {
      this.rotateCanvas(this.lastRotate-this.viewPort.rotate);
      this.lastRotate = this.viewPort.rotate;
    }
    if(this.ovContext != null) {
      this.ovContext.save();
    }
    if(this.context != null) {
      this.context.save();

      this.context.setTransform(1, 0, 0, 1, 0, 0);
      this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);

      this.context.restore();
      this.context.fillStyle = 'grey';
      if(this.debugging)
        this.context.fillRect((this.context.canvas.width / 2)-2, -(this.context.canvas.height/2), 2,this.context.canvas.height * 2);
      this.context.fillStyle = 'blue';
      if(this.debugging)
        this.context.fillRect(-(this.context.canvas.width/2), (this.context.canvas.height / 2)-2, this.context.canvas.width * 2,2);

    }

    let fenceItemsToRender = this.fenceItems;
    let pointerInBounds = false;
    let lastFenceItemAngle = -1;
    let lastDifferentFenceItemAngle = -1;

    for(let i = 0; i < fenceItemsToRender.length; i++) {
      let position = this.relateItemPositionToViewPort(fenceItemsToRender[i].position);
      if (this.selectedFenceItemIndex === fenceItemsToRender[i].id) {
        this.context.fillStyle = '#ed245f';
      } else {
        this.context.fillStyle = 'grey';
      }
      let minimumX: number;
      let maximumX: number;
      let minimumY: number;
      let maximumY: number;
      if(position.start.x <= position.end.x) {
        minimumX = position.start.x;
        maximumX = position.end.x;
      }else {
        minimumX = position.end.x;
        maximumX = position.start.x;
      }
      if(position.start.y <= position.end.y) {
        minimumY = position.start.y;
        maximumY = position.end.y;
      }else {
        minimumY = position.end.y;
        maximumY = position.start.y;
      }
      const fence = new Path2D();
      const fenceHitBox = new Path2D();
      fence.rect(minimumX-3.5, minimumY-3.5, Math.max( Math.abs(minimumX - maximumX), 4), Math.max(Math.abs(minimumY - maximumY), 4));
      fenceHitBox.rect(minimumX-7.5, minimumY-7.5, Math.max( Math.abs(minimumX - maximumX), 10), Math.max(Math.abs(minimumY - maximumY), 10));
      if(this.context.isPointInPath(fenceHitBox, this.lastMousePosition.x,  this.lastMousePosition.y) && this.mouseOverCanvas) {
        this.context.fillStyle = '#a40e3a';
        this.cursor = 'pointer';
        pointerInBounds = true;
        if(this.canvasClicked) {
          this.canvasClicked = false;
          EventBus.$emit('select-fence-item', fenceItemsToRender[i].id);
        }
      }
   //   this.context.fill(fenceHitBox);
   //   this.context.fillStyle = 'grey';

      this.context.fill(fence);
      this.context.save();
      this.context.translate(((minimumX+maximumX)  / 2), (minimumY+maximumY) / 2);
  //    lastFenceItemAngle = fenceItemsToRender[i].leftFenceItem != null ? this.getAngle(fenceItemsToRender[i].leftFenceItem) : -1;
      let fenceItemAngle = this.getAngle(fenceItemsToRender[i]);

   //   lastDifferentFenceItemAngle = lastFenceItemAngle;

      let indx = 0;

    //  while (lastDifferentFenceItemAngle === fenceItemAngle) {
      //  if(fenceItemsToRender[i].leftFenceItem)
  //    }

      //if(lastFenceItemAngle !== fenceItemAngle) {
       // lastDifferentFenceItemAngle = lastFenceItemAngle;
      //}

      this.context.textAlign = 'center';
      this.context.rotate((fenceItemAngle) * (Math.PI / 180));
      //  this.rotateCanvas(fenceItemAngle);
      if (fenceItemsToRender[i].type === FenceItemType.FenceField) {
        this.context.strokeText('H: ' + fenceItemsToRender[i].field.totalFenceHeight.toFixed() +' cm', 0, -15);
      } else if(fenceItemsToRender[i].type === FenceItemType.FenceDoor) {
        this.context.strokeText('Tor', 0, -15);
      }

      if(fenceItemAngle === 360 || fenceItemAngle === 0 || fenceItemAngle == 180) {

        //  this.context.strokeText(fenceItemsToRender[i].field.totalFenceHeight+'cm', 0,-15)
        //  if(lastDifferentFenceItemAngle === 90) {
        //    this.context.strokeText(fenceItemAngle, 0,25)
        //   }else {
        //    this.context.strokeText(fenceItemAngle, 0,-15)
        //}

      } else if(fenceItemAngle === 90 || fenceItemAngle === 270) {
        //  this.context.strokeText(fenceItemsToRender[i].field.totalFenceHeight+'cm', 0,-15);
        //   if (lastDifferentFenceItemAngle === 360) {
        //    this.context.strokeText(fenceItemAngle, 0,25);
        //   } else if(lastDifferentFenceItemAngle === 180) {
        //     this.context.strokeText(fenceItemAngle, 0,25);
        //   } else {
        //     this.context.strokeText(fenceItemAngle, 0,-15);
        //   }
      }
      this.context.restore();
      if(fenceItemsToRender[i].type === FenceItemType.FenceField) {
        this.context.fillRect(minimumX-5.5, minimumY-5.5, 7.5, 7.5);
        this.context.fillRect(maximumX-5.5, maximumY-5.5, 7.5, 7.5);
      }
     // this.ovContext.fillRect(0, 0, 2, this.ovContext.canvas.height);
     // this.ovContext.fillRect(0, 0, this.ovContext.canvas.width, 2);
      //this.drawDottedLine(1,0,1,this.ovContext.canvas.height);
      //this.drawDottedLine(0,1,this.ovContext.canvas.width,1);
   //   lastFenceItemAngle = fenceItemAngle;
    }
    if(!pointerInBounds) {
      this.cursor = 'default';
    }
    if(this.canvasClicked) {
      this.canvasClicked = false;
    }
    if(this.context != null) {
      this.context.fillStyle = 'black';
    }
  //  this.context.fillRect(-20, 0,  100, 100);
  }

  draw(): void {
    if (this.context == null && this.$refs.canvas !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.context = this.$refs.canvas.getContext("2d");
      const canvasParent = document.getElementsByClassName('cg-top-viewport');
      if (canvasParent && canvasParent.length > 0) {
        this.context.canvas.width = canvasParent[0].clientWidth;
        this.context.canvas.height = canvasParent[0].clientHeight;
      }
      
    }
    if(this.ovContext == null && this.$refs.canvasoverlay !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.ovContext = this.$refs.canvasoverlay.getContext("2d");
    }
    this.viewPort.x = this.actualViewPort.x;
    this.viewPort.y = this.actualViewPort.y;
    this.viewPort.targetRotate = this.actualViewPort.targetRotate;
    this.viewPort.renderedX = this.lerp(this.viewPort.renderedX, this.viewPort.x, 0.225);
    this.viewPort.renderedY = this.lerp(this.viewPort.renderedY, this.viewPort.y, 0.225);
    this.viewPort.rotate = this.lerpDegrees(this.viewPort.rotate, this.viewPort.targetRotate, 0.175);
    this.drawFenceItems();
    if (this.running) {
      setTimeout(this.draw, 200);
    }
  }

  mounted(): void {
    this.running = true;
    setTimeout(() => this.draw(), 2000);
  }

  unmounted(): void {
    this.running = false;
  }

  mouseOverCanvas = false;

  onMouseEnter(event): void {
    this.mouseOverCanvas = true;
  }

  onMouseLeave(event): void {
    this.mouseOverCanvas = false;
  }
  lastMousePosition: {
    x: number;
    y: number;
  } = {x: 0, y: 0};
  onMouseMove(event): void {
    // eslint-disable-next-line no-undef
    let offset = $(this.$refs.canvas).offset();
    let left = event.pageX - offset.left;
    let top = event.pageY - offset.top;
    this.lastMousePosition.x = left;
    this.lastMousePosition.y = top;
  }
  canvasClicked = false;
  onClick(event): void {
    this.canvasClicked = true;
  }
}
