
import Vue from 'vue';
import Configurator from "@/components/Configurator.vue";
import {ConfigurationModel} from "@/store/modules/configuration";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
//import {parse, stringify, toJSON, fromJSON} from 'flatted';
import {parse, stringify} from 'flatted';
import {saveAs} from 'file-saver';
import selectFiles from 'select-files';


export default Vue.extend({
  name: 'App',

  components: {
    Configurator,
  },

  data: () => ({
    loaded: false,
    showSaveDialog: false,
    saveName: '',
    showCreateDialog: false,
    createName: '',
    showOpenDialog: false,
    selectedConfig: -1,
    showResetDialog: false
    //
  }),
  computed: {
    canSave: function () {
      if (this.$store.state['Configuration'].currentSelectedConfiguration > 0) {
        let id = this.$store.state['Configuration'].currentSelectedConfiguration;
        let currentConfiguration = this.getConfigurationById(id);
        if (currentConfiguration.saveStamp === this.$store.state['Configuration'].lastSaveStamp) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    canRestore: function () {
      if (this.$store.state['Configuration'].currentSelectedConfiguration > 0) {
        let id = this.$store.state['Configuration'].currentSelectedConfiguration;
        let currentConfiguration = this.getConfigurationById(id);
        if (currentConfiguration.saveStamp === this.$store.state['Configuration'].lastSaveStamp) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    canExport: function () {
      return this.$store.state['Configuration'].currentSelectedConfiguration > 0;
    }
  },
  methods: {
    loadSelectedConfig(): void {
      if (this.selectedConfig !== -1) {
        this.loadConfiguration(this.$store.state['Configuration'].configurations[this.selectedConfig].id);
      }
      this.closeOpenDialog();
    },
    async loadConfiguration(id: number): Promise<void> {
      let config = this.getConfigurationById(id);
      if (config != null) {
        this.loaded = false;
        await this.$store.commit("saveItems", config.fenceItems);
        await this.$store.commit("updateCoverType", config.fenceCoverType);
        await this.$store.commit("updateCoverColor", config.fenceCoverColor);
        await this.$store.commit("updateSelectedItemIndex", config.selectedItemIndex);
        await this.$store.commit("updateCurrentConfigurationId", id);
        await this.$store.commit("updateLastSaveStamp", config.saveStamp);
        this.loaded = true
        // dom flush wait needed?
        // this.$nextTick(() => {
          // this.$nextTick(() => {
          //   this.loaded = true;
          //   this.$nextTick(() => {
          //     this.loaded = false;
          //     this.$nextTick(() => {
          //       this.$nextTick(() => {
          //         this.loaded = true;
          //       });p.
          //     });
          //   });
          // });
        // });
      }
    },
    getConfigurationById(id: number): ConfigurationModel {
      return this.$store.state['Configuration'].configurations.find(value => value.id === id);
    },
    onSelectedConfigChange(val): void {
      this.selectedConfig = val;
    },
    closeOpenDialog(): void {
      this.showOpenDialog = false;
      this.selectedConfig = -1;
    },
    openOpenDialog(): void {
      if (this.$store.state['Configuration'].currentSelectedConfiguration > 0) {
        let id = this.$store.state['Configuration'].currentSelectedConfiguration;
        let currentConfiguration = this.getConfigurationById(id);
        if (currentConfiguration.saveStamp === this.$store.state['Configuration'].lastSaveStamp) {
          this.showOpenDialog = true;
        } else {
//
        }
      } else {
        this.showOpenDialog = true;
      }
    },
    closeCreateDialog(): void {
      this.createName = "";
      this.showCreateDialog = false;
    },
    openCreateDialog(): void {
      this.showCreateDialog = true;
    },
    async createConfiguration(): Promise<void> {
      let id = Math.floor(Math.random() * Math.pow(performance.now(), 2));
      let configuration: ConfigurationModel = {
        id,
        name: this.createName,
        fenceItems: [],
        saveStamp: 0,
        selectedItemIndex: 0,
        fenceCoverType: null,
        fenceCoverColor: null
      }
      await this.$store.commit('saveConfiguration', configuration);
      this.loadConfiguration(id);
      this.closeCreateDialog();
    },
    keydownListener(e): void {
      if (e.keyCode != null && e.ctrlKey != null) {
        if (e.keyCode === 83 && e.ctrlKey === true) {
          e.preventDefault();
          if (this.canSave) {
            this.openSaveDialog();
          }
        } else if (e.keyCode == 70 && e.ctrlKey == true) {
          e.preventDefault();
          if (!this.canSave) {
            this.openCreateDialog();
          }
        }
      }
    },
    closeSaveDialog(): void {
      this.saveName = "";
      this.showSaveDialog = false;
    },
    openSaveDialog(): void {
      if (this.$store.state['Configuration'].currentSelectedConfiguration > 0) {
        let currentConfiguration = this.$store.state['Configuration'].configurations.find(value => value.id === this.$store.state['Configuration'].currentSelectedConfiguration);
        if (currentConfiguration != null) {
          currentConfiguration.fenceItems = this.$store.state['Configuration'].fenceItems;
          currentConfiguration.selectedItemIndex = this.$store.state['Configuration'].selectedItemIndex;
          this.$store.commit('saveConfiguration', currentConfiguration);
        } else {
          this.showSaveDialog = true;
        }
      } else {
        this.showSaveDialog = true;
      }
    },
    async saveConfiguration(): Promise<void> {
      let configuration: ConfigurationModel = {
        id: Math.floor(Math.random() * Math.pow(performance.now(), 2)),
        name: this.saveName,
        fenceItems: this.$store.state['Configuration'].fenceItems,
        saveStamp: 0,
        selectedItemIndex: this.$store.state['Configuration'].selectedItemIndex,
        fenceCoverType: this.$store.state['Configuration'].fenceCoverType,
        fenceCoverColor: this.$store.state['Configuration'].fenceCoverColor
      }
      await this.$store.commit('saveConfiguration', configuration);
      this.closeSaveDialog();
    },
    restoreCurrentConfiguration(): void {
      let id = this.$store.state['Configuration'].currentSelectedConfiguration;
      this.loadConfiguration(id);
    },
    exportCurrentConfiguration(): void {
      if (this.canExport) {
        let id = this.$store.state['Configuration'].currentSelectedConfiguration;
        let currentConfiguration = this.getConfigurationById(id);
        let json = stringify(currentConfiguration);
        saveAs(new Blob([json], {type: "application/json;charset=utf-8"}), currentConfiguration.name + '.json')
      }
    },
    importCurrentConfiguration(): void {
      selectFiles({accept: '.json', multiple: false}).then(files => {
        if (files.length > 0) {
          let reader = new FileReader();
          reader.onload = async () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            let cfg = parse(reader.result);
            if (cfg != null) {
              await this.$store.commit('saveConfiguration', cfg);
              await this.loadConfiguration(cfg.id);
            }
          }
          reader.readAsText(files[0], 'utf-8');
        }
      });
    },
    openResetDialog(): void {
      this.showResetDialog = true;
    },
    async resetCurrentConfig() {
      //look and make id
      let currentConfigurationId = this.$store.state['Configuration'].configurations[this.selectedConfig]?.id;
      if(currentConfigurationId == null) {
        currentConfigurationId = Math.floor(Math.random() * Math.pow(performance.now(), 2));
      }
      //----
      // Setup New ConfigurationModel
      let configuration: ConfigurationModel = {
        id: currentConfigurationId,
        name: this.createName,
        fenceItems: [],
        saveStamp: 0,
        selectedItemIndex: 0,
        fenceCoverType: null,
        fenceCoverColor: null
      }
      //----
      // saving fresh Configuration
      await this.$store.commit('saveConfiguration', configuration);
     //---
      // load fresh Configuration
      this.loadConfiguration(currentConfigurationId);
      this.selectedConfig = currentConfigurationId;
      // setTimeout(() => {
      //   this.loadConfiguration(currentConfigurationId)
      //   this.selectedConfig = currentConfigurationId;
      // }, 150);
      this.showResetDialog = false;
    }
  },
  mounted() {
    let waitForStorage = async () => {
      await this.$store.restored;
      document.addEventListener('keydown', this.keydownListener);
      this.loaded = true
      if (this.$store.state['Configuration'].currentSelectedConfiguration > 0) {
        let id = this.$store.state['Configuration'].currentSelectedConfiguration;
        this.selectedConfig = id;
        let currentConfiguration = this.getConfigurationById(id);
        if (currentConfiguration.saveStamp === this.$store.state['Configuration'].lastSaveStamp) {
          await this.loadConfiguration(id);
        }
      }
    }
    //TODO: remove console log
    //console.log(this.$store.restored);
    waitForStorage();
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  unmounted() {
    document.removeEventListener('keydown', this.keydownListener);
  }
});
