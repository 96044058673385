import Vue from 'vue'
import Vuex, {ModuleTree} from 'vuex'
import VuexPersistence from "vuex-persist";
import {UserData} from './modules/userdata'
import {Configuration} from "@/store/modules/configuration";
import {VuexModule} from "@/store/modules/modules";
import * as localforage from "localforage";

const storeModules: ModuleTree<VuexModule> = {
  UserData,
  Configuration
};

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: localforage,
  modules: ['UserData', 'Configuration'],
  supportCircular: true,
  asyncStorage: true
});

const store = new Vuex.Store({
  modules: storeModules,
  plugins: [
    vuexLocal.plugin
  ]
});
export default store
