import {FenceCoverColor, FenceCoverType} from "../../types/editor";

export interface Texture {
    type: string;
    series: string;
    label: string;
    name: string;
    file: string;
    color: FenceCoverColor | null;
    coverType: FenceCoverType | null;
}

class TexturesHelper {
    static textures: Array<Texture> = require("../assets/textures/textures.json");

    static getPath(name: string, series: string, type: string): string {
        const texture = this.textures.find(value => value.name === name && value.series === series && value.type === type);
        if(texture != null && texture.file != null) {
            return (`/textures/${series}/${type}/` + texture.file);
        }
        return '';
    }

    static getTexturePath(file: string, series: string, type: string): string {
        const texture = this.textures.find(value => value.file === file && value.series === series && value.type === type);
        if(texture != null && texture.file != null) {
            return (`/textures/${series}/${type}/` + texture.file);
        }
        return '';
    }

    static getDoorTexturePath(): string {
        return '/textures/gate/gate/gate.png';
    }

    static getPostTexturePath(series: string, color: FenceCoverColor, coverType: FenceCoverType): string {
        const texture = this.getPostTexture(series, color, coverType);
        if(texture != null && texture.file != null) {
            return (`/textures/${series}/post/` + texture.file);
        }
        return '';
    }

    static getTexture(name: string, series: string, type: string): Texture | null {
        return this.textures.find(value => value.name === name && value.series === series && value.type === type);
    }

    static getPostTexture(series: string, color: FenceCoverColor, coverType: FenceCoverType): Texture | null {
        return this.textures.find(value => value.series === series && value.type === 'post' && value.color === color && value.coverType === coverType);
    }
}
export default TexturesHelper;
