import {Module} from "vuex";
import {VuexModule} from "@/store/modules/modules";

export interface UserState extends VuexModule{
    id: string;
}

export const state: UserState = {
    name: 'UserState',
    id: '',
}

export const UserData: Module<UserState, any> = {
    state,
    mutations: {

    },
    actions: {

    },
    getters: {

    }
}
